<template>
  <div class="goods-list" v-loading="loading">
    <Search></Search>
    <div class="l-contain clearfix">
      <div class="l-hd">
        <div class="nav">
          <ul class="n-ul">
            <li
              class="n-item"
              :class="firstid == item.id ? 'n-current' : ''"
              v-for="(item, index) in catelist"
              :key="index"
              @click="seccate(item.id, index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <!-- 分类 -->
        <div class="l-classify">
          <div class="c-main clearfix">
            <span class="c-title">分类:</span>
            <div class="c-list">
              <div
                class="l-item"
                :class="twoid == 0 ? 'm-current' : ''"
                @click="sectwo(0)"
              >
                全部
              </div>
              <div
                class="l-item"
                :class="twoid == item.id ? 'm-current' : ''"
                v-for="(item, index) in twolist"
                :key="index"
                @click="sectwo(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="n-page">
              <div class="p-title">
                共
                <b>{{ count }}</b>
                件商品
                <span class="p-num">
                  <b>{{ page }}</b>
                  /{{ Math.ceil(count / limit) }}</span
                >
              </div>
              <ul class="p-btn">
                <li class="b-item" @click="getlist(page - 1)">
                  <i class="fa fa-angle-left"></i>
                </li>
                <li class="b-item b-current" @click="getlist(page + 1)">
                  <i class="fa fa-angle-right"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="c-sort">
            <span>排序:</span>
            <div class="s-list">
              <div
                :class="sort == 1 ? 'l-more l-current' : 'l-more'"
                @click="goodssort(1)"
              >
                <span>综合</span>
              </div>
              <div
                :class="sort == 2 ? 'l-sales l-current' : 'l-sales'"
                @click="goodssort(2)"
              >
                <span class="l-txt">销量</span>
                <span style="margin-left: 5px">
                  <i class="fa fa-long-arrow-down"></i>
                </span>
              </div>
              <div
                class="l-price"
                :class="
                  sort == 3 && sorttype == 1
                    ? 'l-itemUp'
                    : sort == 3 && sorttype == 2
                    ? 'l-itemDown'
                    : ''
                "
                @click="goodssort(3)"
              >
                <span class="l-txt">价格</span>
                <i class="fa fa-caret-up l-up"></i>
                <i class="fa fa-caret-down l-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-main">
        <div class="item" v-for="(item, index) in goodslist" :key="index">
          <a
            class="pictrue"
            :href="'/store/goods?id=' + item.id"
            target="_blank"
          >
            <img :src="item.img" />
          </a>
          <div class="price">
            <span class="font-color">¥{{ item.price }}</span>
            <span class="y_money">¥{{ item.originalprice }}</span>
          </div>
          <div class="i-name">
            <a :href="'/store/goods?id=' + item.id" target="_blank">{{
              item.goods_name
            }}</a>
          </div>
          <div class="i-footer">
            <div class="store-name">
              <a :href="'/store/home?id=' + item.store_id" target="_blank">{{
                item.store_name
              }}</a>
            </div>
            <div class="sales">
              成交量:&nbsp;<span>{{ item.vbep }}笔</span>
            </div>
          </div>
        </div>
      </div>
      <div class="l-footer">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="count"
          :page-size="limit"
          @current-change="getlist()"
          :current-page.sync="page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import Search from '../../components/search.vue'
  import { GoodsCate, GoodsList } from '@/api/index'
  export default {
    components: {Search},
    name: "goodsList",
    data() {
      return {
        id: 0,
        firstid: 0,
        twoid: 0,
        twolist: [], // 二级分类
        sort: 1, // 排序 1为综合 2为销量 3为价格
        sorttype: 1, // 排序方式 1 为从低到高 2为从高到底
        catelist: [],
        goodslist: [],
        page: 0,
        limit: 30,
        count: 0,
      }
    },
    methods: {
      seccate(id, index) { // 一级分类点击
        if (index == this.firstid) return false
        this.sort = 1;
        this.sorttype = 1;
        this.firstid = id
        this.twolist = this.catelist[index].children
        this.twoid = 0;
        this.getlist(1);
      },
      sectwo(index) { // 二级分类点击
        this.twoid = index;
        this.getlist(1);
      },
      goodssort(sort) { // 排序点击
        this.sort = sort
        if (sort == 3) this.sorttype = this.sorttype == 1 ? 2 : 1;
        else this.sorttype = 0;
        this.getlist(1);
      },
      async getlist(page) {
        if (page == 0) return false;
        if (page > this.count + 1) return false;
        this.loading = true;
        this.page = page;
        var data = { firstid: this.firstid, twoid: this.twoid, sort: this.sort, sorttype: this.sorttype, page: this.page, limit: this.limit };
        const { data: res } = await GoodsList(data);
        this.loading = false;
        this.goodslist = res.result.list
        this.count = res.result.count;
      },
      async goodscate() { // 分类初始化函数
        this.loading = true;
        const { data: res } = await GoodsCate();
        this.catelist = res.result.classify
        for (var i = 0; i < this.catelist.length; i++) {
          for (var j = 0; j < this.catelist[i].children.length; j++) {
            if (this.catelist[i].children[j].id == this.twoid) {
              this.firstid = this.catelist[i].id;
              this.twolist = this.catelist[i].children;
              this.twoid = this.catelist[i].children[j].id
            }
          }
        }
        this.loading = false;
        this.getlist(1);
      }
    },
    created() {
      this.twoid = this.$route.query.id;
      if (typeof this.twoid !== 'number' && isNaN(this.twoid)) { this.$router.push("/home/error?mes=非法入侵"); return false; }
      this.goodscate()
    }
  }
</script>

<style lang="less" scoped>
.goods-list {
  .m-current {
    color: #e1251b;
  }

  background: #f9f9f9;

  .l-contain {
    width: 1200px;
    margin: 0 auto;
    min-height: 1000px;
    padding-top: 10px;
    position: relative;
    .l-hd {
      .nav {
        height: 50px;
        display: flex;
        justify-content: space-between;
        background: #fff;

        .n-ul {
          display: flex;
          .n-item {
            min-width: 60px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            margin-left: 35px;
            color: #282828;
            font-size: 14px;
            cursor: pointer;

            &:hover {
              color: #e1251b;
            }
          }

          .n-current {
            border-bottom: 2px solid #e1251b;
            color: #e1251b;
          }
        }
      }

      .l-classify {
        height: 80px;
        margin-top: 10px;
        padding: 10px;
        padding-right: 20px;
        font-size: 14px;
        color: #666;
        background: #fff;
        .c-title {
          float: left;
        }

        .c-main {
          width: 100%;
          height: 40px;
          border-bottom: 2px solid #efefef;
          line-height: 40px;
          margin-bottom: 5px;

          .c-list {
            float: left;
            .l-item {
              min-width: 60px;
              height: 100%;
              float: left;
              margin-left: 30px;
              line-height: 40px;
              text-align: center;
              cursor: pointer;

              &:hover {
                color: #e1251b;
              }
            }
          }

          .n-page {
            float: right;
            line-height: 40px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            .p-title {
              b {
                color: #e1251b;
              }

              .p-num {
                margin: 0px 10px;
              }
            }

            .p-btn {
              display: flex;

              .b-item {
                width: 48px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                border: 1px solid #ddd;
                position: relative;

                i {
                  font-size: 20px;
                  position: absolute;
                  top: 3px;
                  left: 20px;
                  color: #aaa;
                }
              }

              .b-current {
                border-left: none;
              }
            }
          }
        }

        .c-sort {
          height: 30px;
          line-height: 30px;
          display: flex;
          color: #666;
          margin-top: 8px;

          .s-list {
            display: flex;
            margin-left: 30px;

            .l-more {
              min-width: 60px;
              height: 30px;
              text-align: center;
              border: 1px solid #ddd;
              box-sizing: border-box;
              cursor: pointer;

              &:hover {
                color: #e1251b;
                border: 1px solid #e1251b;
              }
            }

            .l-sales {
              min-width: 60px;
              height: 30px;
              padding: 0px 10px;
              position: relative;
              border: 1px solid #ddd;
              box-sizing: border-box;
              cursor: pointer;

              .l-up {
                position: absolute;
                top: 3px;
                right: 6px;
              }

              .l-down {
                position: absolute;
                top: 11px;
                right: 6px;
              }
            }

            .l-price {
              min-width: 60px;
              height: 30px;
              position: relative;
              border: 1px solid #ddd;
              box-sizing: border-box;
              padding: 0px 10px;
              cursor: pointer;

              &:hover {
                .l-txt {
                  color: #e1251b;
                }

                border: 1px solid #e1251b;
              }

              .l-up {
                position: absolute;
                top: 3px;
                right: 6px;
              }

              .l-down {
                position: absolute;
                top: 11px;
                right: 6px;
              }
            }

            .l-current {
              border: 1px solid #e1251b;
              background: #e1251b;
              color: #fff;

              &:hover {
                border: 1px solid #e1251b;
                background: #e1251b;
                color: #fff;
              }
            }

            .l-itemUp {
              cursor: pointer;
              border: 1px solid #e1251b;

              .l-txt {
                color: #fff;
              }

              background: #e1251b;

              .l-up {
                color: #fff;
              }

              &:hover {
                .l-txt {
                  color: #fff;
                }

                border: 1px solid #e1251b;
              }
            }

            .l-itemDown {
              cursor: pointer;
              border: 1px solid #e1251b;

              .l-txt {
                color: #fff;
              }

              background: #e1251b;

              .l-down {
                color: #fff;
              }

              &:hover {
                .l-txt {
                  color: #fff;
                }

                border: 1px solid #e1251b;
              }
            }
          }
        }
      }
    }

    .l-main {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 240px;
        height: 320px;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 10px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid #ddd;
        }

        .pictrue {
          width: 200px;
          height: 192px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .price {
          margin-top: 10px;

          .font-color {
            font-size: 22px;
            font-weight: bold;
            color: #e1251b;
          }

          .y_money {
            color: #aaaaaa;
            font-size: 12px;
            text-decoration: line-through;
            margin-left: 8px;
          }
        }

        .i-name {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          color: #333;
          margin: 10px 0px;
        }

        .i-footer {
          display: flex;
          justify-content: space-between;

          .sales {
            color: #666;

            span {
              color: #b57c5b;
            }
          }
        }
      }
    }
  }
  .l-footer {
    position: absolute;
    bottom: 5%;
    right: 2%;
  }
}
</style>